<template>
  <b-modal
    :id="id"
    v-model="showModal"
    v-bind="$attrs"
    :dialog-class="fullscreen ? 'full-screen' : ''"
    v-on="$listeners"
  >
    <template
      v-for="(_, slotName) of $scopedSlots"
      v-slot:[slotName]="data"
    >
      <slot
        :name="slotName"
        v-bind="data"
      />

    </template>
  </b-modal>
</template>
<script>

import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'SModal',
  props: {
    id: {
      type: String,
      required: false,
      default() {
        return uuidv4()
      },
    },
    value: {
      type: Boolean,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showModal: false,
      eventHandler: '',
    }
  },
  watch: {
    value(value) {
      this.showModal = value

      if (value && this.draggable) {
        this.eventHandler = event => {
          this.startDragIfDraggable(event)
        }
        document.addEventListener('mousedown', this.eventHandler, false)
      }
    },
    showModal(value) {
      if (!value) {
        if (this.eventHandler) {
          document.removeEventListener('mousedown', this.eventHandler, false)
          this.eventHandler = null
        }
        this.$emit('input', false)
      }
    },
  },
  mounted() {
    this.showModal = this.show
    if (this.fullscreen) {
      const element = document.getElementById(this.id)
      if (element) {
        document.getElementById(this.id).addClass('full-screen')
      }
    }
  },
  destroyed() {
    if (this.eventHandler) {
      document.removeEventListener('mousedown', this.eventHandler, false)
    }
  },
  methods: {
    startDrag(elem, evt) {
      const that = elem
      const originalCursor = elem.style.cursor
      elem.style.cursor = 'move'
      const diffX = evt.clientX - that.offsetLeft
      const diffY = evt.clientY - that.offsetTop
      function moveAlong(evtBubble) {
        if (elem.style) {
          let top = evtBubble.clientY - diffY
          if (top <= 0) {
            top = 0
          }
          const left = evtBubble.clientX - diffX

          elem.style.position = 'absolute'
          elem.style.left = `${left}px`
          elem.style.top = `${top}px`
        }
      }
      function stopDrag() {
        elem.style.cursor = originalCursor
        document.removeEventListener('mousemove', moveAlong)
        document.removeEventListener('mouseup', stopDrag)
      }

      document.addEventListener('mouseup', stopDrag)
      document.addEventListener('mousemove', moveAlong)
    },
    startDragIfDraggable(evt) {
      const element = evt.target

      if (element.id === `${this.id}___BV_modal_header_` || element.id === `${this.id}___BV_modal_title_`) {
        const dragElement = document.getElementById(`${this.id}___BV_modal_content_`)
        this.startDrag(dragElement, evt)
      }
    },
  },
}
</script>

<style>
.modal-title {
    cursor: default;
    user-select: none;
}
.full-screen{
  max-width: 99% !important;;
  margin: 0;
  top: 5px;
  left: 5px;
  right: 5px;
  height: 99vh !important;
  display: flex;
  position: fixed;
}
</style>
